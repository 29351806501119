<template>
    <div class="inner-section">
        <card>
        </card>

        <body-card class="mt-5">
            <!-- table section start -->
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('teaGarden.create_salary') }}</h4>
            </template>
            <template v-slot:body>
                    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                        <b-overlay :show="dataLoading">
                            <b-row>
                                <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"
                                    enctype="multipart/form-data">
                                    <b-row>
                                    <b-col lg="6" md="6" sm="12">
                                        <ValidationProvider name="Garden" vid="garden_id" rules="required|min_value:1" v-slot="{ errors }">
                                        <b-form-group label-cols-sm="3" label-for="garden_id">
                                            <template v-slot:label>
                                            {{ $t('teaGardenConfig.select_garden') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="form.garden_id"
                                                :disabled="isGardenAdmin"
                                                :options="teaGardenGenInfoList"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                        <b-col lg="6" md="6" sm="12">
                                            <ValidationProvider name="Employee Type" vid="employee_type" rules="required|min_value:1" v-slot="{ errors }">
                                                <b-form-group label-cols-sm="3"  label-for="employee_type">
                                                    <template v-slot:label>
                                                        {{ $t('teaGarden.employee_type') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="form.employee_type"
                                                        :options="empTypeList"
                                                        value-field="value" text-field="text"
                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                    </b-form-radio-group>
                                                    <div class="invalid-feedback d-block">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <template v-if="form.employee_type !== 2">
                                            <b-col lg="6" md="6" sm="12">
                                                <ValidationProvider name="Year" vid="year" rules="required|min_value:1" v-slot="{ errors }">
                                                    <b-form-group label-cols-sm="3" label-for="year">
                                                        <template v-slot:label>
                                                            {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="form.year"
                                                            :options="yearList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12">
                                                <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                                                    <b-form-group label-cols-sm="3"
                                                        slot-scope="{ valid, errors }"
                                                        label-for="month">
                                                            <template v-slot:label>
                                                            {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                plain
                                                                v-model="form.month"
                                                                :options="monthList"
                                                                id="month"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </template>
                                        <template v-if="form.employee_type == 2">
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="From Date" vid="from_date" rules="required">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="from_date"
                                                slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.from_date') }}  <span class="text-danger">*</span>
                                                </template>
                                                <date-picker
                                                    id="from_date"
                                                    class="form-control"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    v-model="form.from_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :class="errors[0] ? 'is-invalid' : ''"
                                                >
                                                </date-picker>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider name="To Date" vid="to_date" rules="required">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="3"
                                                label-for="to_date"
                                                slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.to_date') }}  <span class="text-danger">*</span>
                                                </template>
                                                <date-picker
                                                    id="to_date"
                                                    class="form-control"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    v-model="form.to_date"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    disabled
                                                    :class="errors[0] ? 'is-invalid' : ''"
                                                >
                                                </date-picker>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                            </b-col>
                                        </template>
                                    </b-row>
                                    <b-row class="text-right">
                                        <b-col>
                                            <b-button type="submit" variant="success" class="mr-2 btn-md">{{
                                                $t('globalTrans.submit') }}</b-button>
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </b-row>
                        </b-overlay>
                    </ValidationObserver>
            </template>
        </body-card>
    </div>
</template>

<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { managementSalaryStore, staffSalaryStore, staffSalaryMonthlyStore, warkerSalaryMonthlyStore } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
    name: 'Form',
    mixins: [teaGardenService],
    components: {
    },
    data () {
        return {
            valid: null,
            Update: '',
            errors: [],
            editStatus: 0,
            isGardenAdmin: false,
            dataLoading: false,
            form: {
                garden_id: 0,
                employee_type: 1,
                month: 0,
                year: 0,
                from_date: '',
                to_date: ''
            }
        }
    },
    created () {
         this.form.year = new Date().getFullYear()
          if (this.isGardenAdminCheckGardenId()) {
                this.isGardenAdmin = true
                this.form.garden_id = this.isGardenAdminCheckGardenId()
          }
    },
    watch: {
        'form.employee_type': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal === 1) {
                    this.form.from_date = ''
                    this.form.to_date = ''
                } else {
                    this.form.month = 0
                }
            }
        },
        'form.from_date': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                const date = new Date(newVal)
                const endDate = new Date(date.setDate(date.getDate() + 6))
                this.form.to_date = endDate
            }
        }
    },
    computed: {
        empTypeList: function () {
        return this.$store.state.TeaGardenService.commonObj.employeeType
        },
        yearList () {
            return this.$store.state.TeaGardenService.commonObj.yearList.map(el => {
                return Object.assign({ value: el, text: this.$i18n.locale === 'en' ? el : this.$n(el, { useGrouping: false }) })
            })
        },
        monthList () {
            return this.$store.state.commonObj.monthList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
            })
        },
        teaGardenGenInfoList: function () {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
        }
    },
    methods: {
        async saveUpdate () {
            this.dataLoading = true
            let result = null
            if (this.form.employee_type === 2) {
                result = await RestApi.postData(teaGardenServiceBaseUrl, staffSalaryStore, this.form)
            } else if (this.form.employee_type === 3) {
                result = await RestApi.postData(teaGardenServiceBaseUrl, staffSalaryMonthlyStore, this.form)
            } else if (this.form.employee_type === 4) {
                result = await RestApi.postData(teaGardenServiceBaseUrl, warkerSalaryMonthlyStore, this.form)
            } else {
                result = await RestApi.postData(teaGardenServiceBaseUrl, managementSalaryStore, this.form)
            }

            if (result.success) {
                this.dataLoading = false
                this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: 'Success',
                    message: 'Salary Created Successfully!',
                    color: '#D6E09B'
                })
            } else {
                if (result.salary_error !== undefined && result.salary_error) {
                     this.dataLoading = false
                        this.$toast.success({
                            title: 'Error',
                            message: result.message,
                            color: '#cc3300'
                        })
                } else {
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
